import React, { useEffect, useState } from "react";
import { withRouter, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    useTheme,
    styled
} from "@mui/material";
import { FaCheck, FaTimes, FaCrown, FaRocket, FaUserAlt } from "react-icons/fa";


const StyledCard = styled(Card)(({ theme, isPopular }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s ease-in-out",
    position: "relative",
    border: isPopular ? `2px solid ${theme.palette.primary.main}` : "none",
    "&:hover": {
        transform: "translateY(-10px)",
        boxShadow: theme.shadows[10]
    }
}));

const PlanBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "4px 12px",
    borderRadius: "16px",
    fontSize: "0.875rem"
}));

const SignUpPlans = () => {
    const theme = useTheme();
    const [hoveredPlan, setHoveredPlan] = useState(null);
    const [useruniqueid, setuseruniqueid] = useState(null)
    const params = useParams();

    const plans = [
        {
            title: "Personal",
            price: "$20",
            period: "/month",
            forWhom: "",
            icon: <FaUserAlt size={24} />,
            features: [
                "Limited features during trial",
                "30 Interactive Problems during trial, unlimited afterwards",
                "Limited Video Lectures during trial",
                "Limited access to Practice Tests during trial, with AI grading + interactive feedback",
                "Personalized 'Proficiency Map' and 'Knowledge Gaps' for further study"
            ],
            buttonText: "Start your 30-Day Free Trial",
            //  buttonColor: "inherit",
            buttonColor: "primary",
            popular: false
        },
        {
            title: "Teacher Sponsored",
            price: "$0",
            period: "/month",
            forWhom: "to the Student",
            icon: <FaRocket size={24} />,
            features: [
                "Additional features access",
                "Unlimited Interactive Problems",
                "Limited Video Lectures",
                "Full access to 'Practice Tests', with AI grading + interactive feedback",
                "Personalized 'Proficiency Map' and 'Knowledge Gaps' for further study"
            ],
            buttonText: "Teacher sponsored Access",
            buttonColor: "primary",
            popular: false
        },
        // {
        //     title: "Premium",
        //     price: "$99",
        //     period: "/month",
        //     icon: <FaCrown size={24} />,
        //     features: [
        //         "All Basic features",
        //         "Unlimited users",
        //         "24/7 Priority support",
        //         "Custom analytics",
        //         "Unlimited storage"
        //     ],
        //     buttonText: "Go Premium",
        //     buttonColor: "secondary",
        //     popular: false
        // }
    ];

    const handleSubscribe = (plan) => {
        debugger;
        if (plan === "Personal") {
            window.location = "/Signup/personal" + (useruniqueid !== null && useruniqueid !== "null" && useruniqueid !== undefined ? "/" + useruniqueid : "" )
        }
        else {
            window.location = "/Signup/sponsored" + (useruniqueid !== null && useruniqueid !== "null" && useruniqueid !== undefined ? "/" + useruniqueid : "" )
        }

        //  console.log(`Subscribed to ${plan} plan`);
    };

    useEffect(() => {

        var userUniqueId = params.useruniqueid
        if (userUniqueId !== undefined && userUniqueId !== null && userUniqueId !== "") {
            setuseruniqueid(userUniqueId)
        }
    }, [])

    return (
        <Container maxWidth="lg" sx={{ py: 8 }}>
            <Typography
                variant="h2"
                align="center"
                gutterBottom
                sx={{ mb: 6 }}
                component="h1"
            >
                Choose Your Plan
            </Typography>
            <Grid container spacing={4} alignItems="stretch">
                {plans.map((plan) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        key={plan.title}
                        role="region"
                        aria-label={`${plan.title} subscription plan`}
                    >
                        <StyledCard
                            isPopular={plan.popular}
                            onMouseEnter={() => setHoveredPlan(plan.title)}
                            onMouseLeave={() => setHoveredPlan(null)}
                        >
                            {plan.popular && <PlanBadge>Most Popular</PlanBadge>}
                            <CardContent sx={{ flexGrow: 1, p: 4 }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 2,
                                        color: plan.popular ? "primary.main" : "text.primary"
                                    }}
                                >
                                    {plan.icon}
                                    <Typography variant="h4" component="h2" sx={{ ml: 1 }}>
                                        {plan.title}
                                    </Typography>
                                </Box>

                                <Box sx={{ mb: 4 }}>
                                    <Typography variant="h3" component="p">
                                        {plan.price}
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            sx={{ color: "text.secondary" }}
                                        >
                                            {plan.period}
                                        </Typography>
                                        <Typography component="span" sx={{ fontSize: '22px', paddingLeft: '10px' }}>{plan.forWhom}</Typography>
                                    </Typography>

                                </Box>

                                <Box sx={{ mb: 4 }}>
                                    {plan.features.map((feature) => (
                                        <Box
                                            key={feature}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                mb: 2
                                            }}
                                        >
                                            <FaCheck
                                                color={theme.palette.success.main}
                                                size={16}
                                                style={{ marginRight: "8px" }}
                                            />
                                            <Typography>{feature}</Typography>
                                        </Box>
                                    ))}
                                </Box>

                                <Button
                                    variant={hoveredPlan === plan.title ? "contained" : "outlined"}
                                    color={plan.buttonColor}
                                    fullWidth
                                    size="large"
                                    onClick={() => handleSubscribe(plan.title)}
                                    aria-label={`Subscribe to ${plan.title} plan`}
                                    sx={{
                                        mt: "auto",
                                        transition: "all 0.3s ease"
                                    }}
                                >
                                    {plan.buttonText}
                                </Button>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};


export default withRouter(SignUpPlans)